
import { defineComponent, onMounted } from "vue";
import Chart from "@/components/1crm/dashboard/superadmin/Widget11.vue";
import Top5Cabang from "@/components/1crm/dashboard/superadmin/top5cabang.vue";
import FundingBsi from "@/components/1crm/dashboard/superadmin/fundingbsi.vue";
import FundingBNI from "@/components/1crm/dashboard/superadmin/fundingBNI.vue";
import FundingBankJatim from "@/components/1crm/dashboard/superadmin/fundingBankJatim.vue";
import FundingPermata from "@/components/1crm/dashboard/superadmin/fundingPermata.vue";
import FundingNTB from "@/components/1crm/dashboard/superadmin/fundingntbs.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "widgets-mixed",
  components: {
    Chart,
    Top5Cabang,
    FundingBsi,
    FundingBNI,
    FundingBankJatim,
    FundingPermata,
    FundingNTB,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Dashboard"]);

      MenuComponent.reinitialization();
    });
  },
});
