import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-4" }
const _hoisted_4 = { class: "col-xl-4" }
const _hoisted_5 = { class: "row g-5 g-xl-8" }
const _hoisted_6 = { class: "col-xl-4" }
const _hoisted_7 = { class: "col-xl-4" }
const _hoisted_8 = { class: "col-xl-4" }
const _hoisted_9 = { class: "row g-5 g-xl-8" }
const _hoisted_10 = { class: "col-xl-4" }
const _hoisted_11 = { class: "col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Top5Cabang = _resolveComponent("Top5Cabang")!
  const _component_FundingBsi = _resolveComponent("FundingBsi")!
  const _component_FundingBNI = _resolveComponent("FundingBNI")!
  const _component_FundingBankJatim = _resolveComponent("FundingBankJatim")!
  const _component_FundingPermata = _resolveComponent("FundingPermata")!
  const _component_FundingNTB = _resolveComponent("FundingNTB")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Chart, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "info",
          "chart-height": "200"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Chart, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "200"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Top5Cabang, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_FundingBsi, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_FundingBNI, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_FundingBankJatim, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_FundingPermata, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_FundingNTB, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ])
  ], 64))
}